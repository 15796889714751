import {Link, useNavigate} from "react-router-dom";
import ImageThumbnail from "../Shared/ImageThumbnail";
import {convertNumberToLocale} from "../../utils";
import {MinusIcon, PlusIcon} from "@heroicons/react/24/outline";
import {useState} from "react";
import {connect} from "react-redux";
import {setProductForQuote} from "../../reducers";

function ProductRow(props) {
    const product = props.product;
    const [quantity, setQuantity] = useState(1);
    const navigate = useNavigate();

    function showPriceForB2C(product) {
        if (product.hidePriceForB2C && Object.keys(props.crmPerson).length===0)
            return false;
        if (product.hidePriceForB2C && props.crmPerson && props.crmPerson.customerType==="B2B")
            return true;
        if (!product.hidePriceForB2C)
            return true;
    }

    return (<div key={product.id}
                 className={"px-2 sm:px-0 animate__animated animate__slideInUp flex flex-col sm:flex-row gap-2 items-center justify-between w-full border-b py-1 shadow sm:shadow-none sm:hover:shadow-2xl"}>
        <Link to={`/shop/product/${product.id}`}
              className={'w-full flex flex-col sm:flex-row gap-2 sm:items-center justify-between'}>
            <div className={"flex flex-col sm:flex-row sm:items-center w-full sm:w-3/5 gap-2"}>
                <div className={"flex h-60 w-full sm:w-24 sm:h-24 sm:min-w-[6rem]"}>
                    {product.productImages.length > 0 ?
                        <ImageThumbnail image={product.productImages[0]}/> :
                        <span
                            className={"flex items-center justify-center bg-orange-100 h-full w-full rounded-md sm:rounded-full"}>
                                    <svg width="24" height="24" viewBox="0 0 24 24"><title>image_not_supported</title><g
                                        fill="none">
                                        <path
                                            d="M21.9 21.9l-6.1-6.1-2.69-2.69L5 5 3.59 3.59 2.1 2.1.69 3.51 3 5.83V19c0 1.1.9 2 2 2h13.17l2.31 2.31 1.42-1.41zM5 19V7.83l6.84 6.84-.84 1.05L9 13l-3 4h8.17l2 2H5zM7.83 5l-2-2H19c1.1 0 2 .9 2 2v13.17l-2-2V5H7.83z"
                                            fill="#0e7490">

                                        </path></g>
                                    </svg>
                                </span>
                    }
                </div>
                <div className={"flex flex-col gap-2"}>
                    <h3 className="text-sm text-gray-900 font-medium">
                        <span aria-hidden="true" className=""/>
                        {product.name}
                    </h3>
                    <p className="flex flex-col sm:flex-row text-sm text-gray-500"><span
                        className={"font-bold"}>{product.no}</span>
                        <span className={"sm:px-4"}>{product.description}</span></p>
                </div>
            </div>

            <div className={"flex sm:justify-center sm:px-2 w-full sm:w-1/5"}>
                {product.stock > 0 && product.price>0 &&
                    <p className="mt-1 text-sm text-white flex items-center gap-2"><span
                        className={"bg-green-500 flex items-center gap-1 p-2 rounded"}>På
                        lager: <img className="h-5"
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEqUlEQVR4nO2Z20+aZxzH3/ZiHmACShFQDjarnduF+wOWmEjVeqoKyFFRwHTJbvYnkIjDQ1cQFZSqYFtbrIebJo12m96ZLNnabBfatOnU9GKRddU168WatfkuD7Or1sPLC7zYC77Jc/1+vg+/53l+vy8UlVZaaSVdirC1Wh5uW5CF27cLw5Zt2U3LQsGkpZp636UI20vkt6x35FPtkIfbIQu3QXbTgsIbFhROtkJ63bwonTR9dtycVOG0Pbdo1t6jnLE9UMzYnyunbVBMW6G4ZcVh8AXXW/5b18yQXjVDOmGCJGR4LgkaV8VBQ7fcbxKkBP709EV50VzHhnLWDuWMHXHDTxghCRkhDhogHjdANKpblwb1MnbpHY6Tyjn7crLh88f0yB/TQTTavEy+wRp/0WyHnT14HURXmiEMaGyswBffvihUzNgibMKLAloIA9qIJGAUJo8c1AnlXIdFMWPbZBv+FFkjGgj96mdCn/qrhMuJHFh2a163H344agB5vibkDTUtC3wNsvivShZum5jhfU3IHWpE7mDjGs9fy/yKLZrr6H0P4CEYaIBg4IKLsQHySKUEflgDoU8N4Q74AfDg99evxGHA/oJ1eL8aEo8aX9zuw2mvHnmDjfvgBd4L4Hnq/mJsQDFte8k2vNjdhPlHP4Do0R9PcHbQjFxvwx54fn89+O66v+MwYH3F6s671fju8Y/Yrfu/PQS/t2YvvKcOPHfdP4zglVPW0lTDvzVQ/S48eO5a8C/Xlsa++1PWb2nhr5ogDZogDcVfNrv18OkTFHsNEHjq98HnXK7Fh5eql2IfRujgQyZ8PNaOL+fdkI0YIBk3JLTzq5F1fNSvPxQ+55saYoAs+qGITFJ08J+O2bD6dCP68aWN+5D5dBCP6lnZ+Zy38OD2nV+gNUDGwKNqXuxvxi+bj/dAfL/2EwoGm5FPoNnZeXD7zoPTW/UnrYGdGfbQAyv2afHzOwbemJAOaCEa0bICz+2tAqencov+F7jROn/kbTNuwCej7f+X0G4trd1DgVcTfV2TWDYg8NyeKnC6K+ZpDZD0gPaqHNOjJNB2oInFtXuQ9mtYgK9Edk9FbMlGwbWWu7T3/BUdSgKWA02QkklW2XDfwLsqF2OC3zFQGtMjFWjGWX8rVn9f3webzJ3nEAPdlbE/ZETSCfOrmF7YYQ2KfS1YOcJEwvCuCmatBJEkZHrJ5IUtHjJjJbLOBjyyu1TMmzlJyPCC0TAy1IQzA8Y9JpIC//U5ZHWdY95Oi4OGB4wnqcFGnPEao00ZWfEeWM5eeGR1qpgPNCTui2sM9DZEW+JoV5kMeKcKGU5VF2MDJKskcV+cM+yBLXE88JnO8l953Z/Hl5uSrJLEfccFn9GpWs5ylBVSCQnUiVPDWotwRLOZKvhMZ/mzDzrLEw+2dovEfXnDmkgK4CNcR1kSo8VdEvrU9hSUjY1iTQ7HSRL3sVnzFJvxOhHJKkncx8Ztk5XwgY1RJKskcR9JzPie+i2+p/Y1c3jV60ynaos8UhnOchfPUcanjltcT01JzqWaO0e1xNldFcf/Jx+dSHpABnBOT9U2p7tqm+OqXIh5GEkrrbQoJvoXZTdWgBhNh8sAAAAASUVORK5CYII="
                                    alt="verified-account--v1"/></span></p>}
                {(product.stock < 1 && product.stockControlled !== null && product.stockControlled) &&
                    <p className="mt-1 text-sm text-white flex"><span
                        className={"bg-red-300 p-2 rounded"}>Utsolgt</span>
                    </p>}
                {(product.price === 0 || (product.stock < 1 && product.stockControlled !== null && !product.stockControlled)) &&
                    <p className="mt-1 text-sm text-white flex"><span
                        className={"bg-blue-500 p-2 rounded"}>Bestillingsvare</span>
                    </p>}
            </div>

            <div className={"flex sm:justify-center sm:px-2 w-full sm:w-1/5"}>
                {product.purchaseAbleOnWeb && showPriceForB2C(product) && product.price > 0 &&
                    <p className="flex flex-col text-sm font-bold text-gray-900">
                        {product.discountedPrice > 0 &&
                            <span className={"text-red-500"}>Kr {convertNumberToLocale(product.discountedPrice)}</span>}
                        <span
                            className={`${product.discountedPrice > 0 ? "line-through" : ""}`}>Kr {convertNumberToLocale(product.price)}</span>
                    </p>}
                {(!product.purchaseAbleOnWeb || product.price === 0) &&
                    <p className="text-sm font-medium text-gray-900"> - </p>}
            </div>
        </Link>
        <div className={"w-28 flex self-end sm:self-center"}>
            <div className="relative rounded-md">
                <div
                    className="absolute inset-y-0 left-0 flex items-center justify-center px-2 border-r cursor-pointer"
                    onClick={() => quantity > 0 && setQuantity(quantity - 1)}>
                                        <span className="text-gray-500 sm:text-xs"><MinusIcon
                                            className={"h-3 w-3"}/></span>
                    </div>
                    <input
                        type="number"
                        name="quantity"
                        id="quantity"
                        className="block w-full rounded-md border-gray-300 px-8 text-center focus:border-orange-500 focus:ring-orange-500 sm:text-xs"
                        value={quantity}
                        onChange={(e) => setQuantity(Number(e.target.value))}
                    />
                    <div
                        className="absolute inset-y-0 right-0 flex items-center justify-center px-2 border-l cursor-pointer"
                        onClick={() => (quantity < product.stock || !product.stockControlled) && setQuantity(quantity + 1)}>
                                        <span className="text-gray-500 sm:text-xs" id="price-currency"><PlusIcon
                                            className={"h-3 w-3"}/></span>
                    </div>
                </div>
            </div>
        <div className={"flex self-end sm:self-center"}>
            {(product.purchaseAbleOnWeb && showPriceForB2C(product,props.crmPerson) && product.price >0)  ?
                <button className={"default-ag-button text-xs p-1 w-20"}
                        disabled={product.stock <= 0 && product.stockControlled}
                        onClick={(e) => {
                            e.preventDefault();
                            props.addProductToCart(product, quantity);
                        }}>Legg til
                </button> :
                <button className={"default-ag-button text-xs p-1 w-20"}
                        onClick={(e) => {
                            e.preventDefault();
                            props.setProductForQuote(product.no)
                            navigate("/shop/getquote");
                        }}>Be om tilbud
                </button>}
        </div>
    </div>)
}
const mapStateToProps = () => {
  return{}
}
export default connect(mapStateToProps,{setProductForQuote})(ProductRow);