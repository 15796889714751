import React, {useEffect, useState} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {getHighLightedProductsService} from "../../service";
import ProductCard from "../Product/ProductCard";
import {connect} from "react-redux";
import {useFetchHighlightedProducts} from "../../utils/UseFetchConstants";

function HighlightedProductsWidget(props) {

    const {products} = useFetchHighlightedProducts(props.filters)

    if (props.init)
    return (
        <Router basename={process.env.REACT_APP_BASENAME}>
        <div className={"w-full"}>
            <div className={"px-4"}>
                {props.filters && <div className={"grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4"}>
                    {products.map(product =>
                        <ProductCard key={product.id} product={product} reload={true} crmPerson={props.crmPerson}/>)}
                </div>
                }
            </div>
        </div>
    </Router>)
    return (<div className={"flex justify-center items-center w-full max-w-7xl h-96 mx-auto"}>
                <span
                    className="animate-spin relative inline-flex rounded-full h-8 w-8 border-2 border-t-sky-600"></span>
        <span className={"ml-2 text-white text-center animate-pulse"}>loading...</span>
    </div>)
}


const mapStateToProps = ({init,crmPerson}) => {
  return{init:init,crmPerson:crmPerson}
}
export default connect(mapStateToProps,{})(HighlightedProductsWidget);